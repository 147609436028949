import React from 'react';

import styles from './Spinner.module.css';

const Spinner = props => {
    let spinner = null;

    if (props.small) {
        spinner = <div className={styles.SpinnerSmall}>Loading...</div>;
    } else if (props.large) {
        spinner = <div className={styles.Spinner}>Loading...</div>;
    }

    return spinner;
};

export default Spinner;
