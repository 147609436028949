import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import './components/UI/ArticleEditor/ArticleEditor.css';
import adminReducer from './store/reducers/admin';
import authReducer from './store/reducers/auth';
import userReducer from './store/reducers/user';
import leaguesReducer from './store/reducers/leagues';
import seasonsReducer from './store/reducers/seasons';
import teamsReducer from './store/reducers/teams';
import playersReducer from './store/reducers/players';
import gamesReducer from './store/reducers/games';
import locationsReducer from './store/reducers/locations';
import alertsReducer from './store/reducers/alerts';
import announcementsReducer from './store/reducers/announcements';
import newsReducer from './store/reducers/news';
import mediaReducer from './store/reducers/media';
import websiteConfigurationReducer from './store/reducers/websiteConfiguration';
import { watchAdmin, watchAuth, watchUser, watchLeagues, watchSeasons, watchTeams, watchPlayers, watchGames, watchLocations, watchAlerts, watchAnnouncements, watchNews, watchMedia, watchWebsiteConfiguration } from './store/sagas';

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

// const composeEnhancers = compose;

const rootReducer = combineReducers({
    admin: adminReducer,
    auth: authReducer,
    user: userReducer,
    leagues: leaguesReducer,
    seasons: seasonsReducer,
    teams: teamsReducer,
    players: playersReducer,
    games: gamesReducer,
    locations: locationsReducer,
    alerts: alertsReducer,
    announcements: announcementsReducer,
    news: newsReducer,
    media: mediaReducer,
    websiteConfiguration: websiteConfigurationReducer
});

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(watchAdmin);
sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchUser);
sagaMiddleware.run(watchLeagues);
sagaMiddleware.run(watchSeasons);
sagaMiddleware.run(watchTeams);
sagaMiddleware.run(watchPlayers);
sagaMiddleware.run(watchGames);
sagaMiddleware.run(watchLocations);
sagaMiddleware.run(watchAlerts);
sagaMiddleware.run(watchAnnouncements);
sagaMiddleware.run(watchNews);
sagaMiddleware.run(watchMedia);
sagaMiddleware.run(watchWebsiteConfiguration);

const app = (
    <Provider store={store}>
        <App />
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
