import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';

// Initialize Cloud Firestore through Firebase
const prodConfig = {
    apiKey: process.env.REACT_APP_PROD_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_PROD_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_PROD_FIREBASE_DATABASEURL,
    projectId: process.env.REACT_APP_PROD_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PROD_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_PROD_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_PROD_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_PROD_FIREBASE_MEASUREMENT_ID
};

const devConfig = {
    apiKey: process.env.REACT_APP_DEV_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_DEV_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DEV_FIREBASE_DATABASEURL,
    projectId: process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_DEV_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_DEV_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_DEV_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_DEV_FIREBASE_MEASUREMENT_ID
};

const config =
  process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

class Firebase {
    constructor() {
        firebase.initializeApp(config);
        firebase.auth();
        firebase.firestore();
        firebase.storage();
        firebase.analytics();
        this.timestamp = firebase.firestore.FieldValue.serverTimestamp();
        this.firestoreDocumentId = firebase.firestore.FieldPath.documentId();
        this.storage_state_changed = firebase.storage.TaskEvent.STATE_CHANGED;
        this.storage_paused = firebase.storage.TaskEvent.PAUSED;
        this.storage_running = firebase.storage.TaskEvent.RUNNING;
    }

    auth() {
        return firebase.auth();
    }

    db() {
        return firebase.firestore();
    }

    storage() {
        return firebase.storage();
    }

    arrayUnion(value) {
        return firebase.firestore.FieldValue.arrayUnion(value)
    }
}

export default new Firebase();