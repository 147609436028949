import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import './App.scss';
import * as actions from './store/actions';
import Spinner from './components/UI/Spinner/Spinner';

// Spinner
const loading = () => <Spinner />;

// Containers
const MainLayout = React.lazy(() => import('./containers/Layout/MainLayout/MainLayout'));

// Pages
const Login = React.lazy(() => import('./containers/User/Login/Login'));
let Register = null;
if (process.env.NODE_ENV !== 'production') {
    Register = React.lazy(() => import('./containers/User/Register/Register'));
}
const Page404 = React.lazy(() => import('./components/Pages/Page404/Page404'));
const Page500 = React.lazy(() => import('./components/Pages/Page500/Page500'));
const PostRegisterAccount = React.lazy(() => import('./components/Pages/Redirect/PostRegisterAccount/PostRegisterAccount'));
const PostDeleteAccount = React.lazy(() => import('./components/Pages/Redirect/PostDeleteAccount/PostDeleteAccount'));

const App = props => {

    const { onCheckAutoLogin, isAuthenticated } = props;

    useEffect(() => {
        if (!isAuthenticated) {
            onCheckAutoLogin();
        }
    }, [onCheckAutoLogin, isAuthenticated]);

    // Set routes depending on logged in state
    const [routes, setRoutes] = useState(
        <>
            <Switch>
                <Route exact path="/login" name="Login" render={props => <Login {...props} />} />
                {process.env.NODE_ENV !== 'production' ? <Route exact path="/register" name="Register" render={props => <Register {...props} />} /> : null}
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                <Route path="/" render={() => <Spinner />} />
            </Switch>
        </>
    );

    useEffect(() => {
        if (isAuthenticated === false) {
            setRoutes(
                <>
                    <Switch>
                        <Route exact path="/login" name="Login" render={props => <Login {...props} />} />
                        {process.env.NODE_ENV !== 'production' ? <Route exact path="/register" name="Register" render={props => <Register {...props} />} /> : null}
                        <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                        <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                        <Route exact path="/delete-account" name="Account Deleted" render={props => <PostDeleteAccount {...props} />} />
                        <Route exact path="/registration-successful" name="Register" render={props => <PostRegisterAccount {...props} />} />
                        <Redirect to="/login" />
                    </Switch>
                </>
            );
        } else if (isAuthenticated) {
            setRoutes(
                <>
                    <Switch>
                        <Route exact path="/login" name="Login" render={props => <Login {...props} />} />
                        {process.env.NODE_ENV !== 'production' ? <Route exact path="/register" name="Register" render={props => <Register {...props} />} /> : null}
                        <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                        <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                        <Route path="/" name="Home" render={props => <MainLayout {...props} />} />
                    </Switch>
                </>
            );
        }
    }, [isAuthenticated]);

    return (
        <BrowserRouter>
            <React.Suspense fallback={loading()}>{routes}</React.Suspense>
        </BrowserRouter>
    );
};

const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        isAuthenticated: state.auth.isAuthenticated,
        loading: state.auth.loading,
        initialLoading: state.auth.initialLoading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCheckAutoLogin: () => dispatch(actions.checkAutoLogin())
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
