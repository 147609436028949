// ANNOUNCEMENTS REDUCER

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utilities/helpers/helpers';

const initialState = {
    image: null,
    downloadUrl: null,
    uploadImageSuccess: null,
    error: null,
    loading: false
}

const uploadImage = (state, action) => {
    return updateObject( state, {
        downloadUrl: null,
        error: null, 
        loading: true 
    });
}

const uploadImageSuccess = (state, action) => {
    return updateObject( state, {
        downloadUrl: action.downloadUrl,
        uploadImageSuccess: true,
        error: null,
        loading: false
    });
}

const uploadImageFail = (state, action) => {
    return updateObject( state, {
        downloadUrl: null,
        uploadImageSuccess: false,
        error: action.error,
        loading: false
    });
}

const deleteImages = (state, action) => {
    return updateObject( state, {
        error: null, 
        loading: true 
    });
}

const deleteImagesSuccess = (state, action) => {
    return updateObject( state, {
        deleteImagesSuccess: true,
        error: null,
        loading: false
    });
}

const deleteImagesFail = (state, action) => {
    return updateObject( state, {
        deleteImagesSuccess: false,
        error: action.error,
        loading: false
    });
}

const clearUploadImageSuccessStates = (state, action) => {
    return updateObject( state, {
        uploadImageSuccess: null
    });
}

const clearUploadImageErrorStates = (state, action) => {
    return updateObject( state, {
        error: null
    });
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPLOAD_IMAGE: return uploadImage(state, action);
        case actionTypes.UPLOAD_IMAGE_SUCCESS: return uploadImageSuccess(state, action);
        case actionTypes.UPLOAD_IMAGE_FAIL: return uploadImageFail(state, action);
        case actionTypes.DELETE_IMAGES: return deleteImages(state, action);
        case actionTypes.DELETE_IMAGES_SUCCESS: return deleteImagesSuccess(state, action);
        case actionTypes.DELETE_IMAGES_FAIL: return deleteImagesFail(state, action);
        case actionTypes.CLEAR_UPLOAD_IMAGE_SUCCESS_STATES: return clearUploadImageSuccessStates(state, action);
        case actionTypes.CLEAR_UPLOAD_IMAGE_ERROR_STATES: return clearUploadImageErrorStates(state, action);
        default: return state;
    }
}

export default reducer;