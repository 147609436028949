import { put, call } from 'redux-saga/effects';

import * as actions from '../actions/index';
import firebase from '../../components/Integrations/Firebase';

function taskCheck(task) {
    return new Promise(function(resolve, reject) {
        task.on(
            firebase.storage_state_changed,
            snapshot => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                // TO DO: Use progress variable to output progress on UI
                switch (snapshot.state) {
                    case firebase.storage_paused:
                        break;
                    case firebase.storage_running:
                        break;
                    default:
                        break;
                }
            },
            error => {
                reject(error);
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                task.snapshot.ref.getDownloadURL().then(function(downloadUrl) {
                    resolve(downloadUrl);
                });
            }
        );
    });
}

export function* uploadImageSaga(action) {
    try {
        const storageRef = firebase.storage().ref();
        const folder = action.folder;
        const imageName = action.imageName;
        const image = action.image;

        const imageRef = yield storageRef.child(folder + '/' + imageName);
        const uploadTask = yield imageRef.put(image);

        let updatedDownloadUrl = null;

        // Listen for state changes, errors, and completion of the upload.
        updatedDownloadUrl = yield call(taskCheck, uploadTask.task);
        yield put(actions.uploadImageSuccess(updatedDownloadUrl));
    } catch (error) {
        switch (error.code) {
            case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;

            case 'storage/canceled':
                // User canceled the upload
                break;

            case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            default:
                break;
        }
        // console.log('[uploadImageSaga > error]', );
        yield put(actions.uploadImageFail(error));
    }
}

export function* deleteImagesSaga(action) {
    try {
        const images = action.images;
        // Create a reference to the file to delete
        yield images.map(image => {
            const imageRef = firebase.storage().refFromURL(image);
            imageRef.delete();
            return null;
        });
        yield put(actions.deleteImagesSuccess());
    } catch (error) {
        // console.log('[deleteImagesSaga > error]', error);
        yield put(actions.deleteImagesFail(error));
    }
}
